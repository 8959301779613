<!-- APP下载页 -->
<template>
  <div class="downloadPage">
    <img class="logo" src="../../assets/images/yx_Logo.png" alt="" />
    <div class="main">
      <img class="icon" src="../../assets/images/appIcon.png" alt="" />
      <div class="app">易鑫金融</div>
      <div class="title">低首付购新车 喜欢就开回家</div>
      
      <div class="c_redBtn" @click="downladAPP">下载 APP</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "downloadPage",
  methods: {
    downladAPP() {
      location.href =
        "https://apps.apple.com/cn/app/%E6%98%93%E9%91%AB%E9%87%91%E8%9E%8D-%E5%88%86%E6%9C%9F%E4%B9%B0%E5%8D%96%E6%96%B0%E8%BD%A6%E4%BA%8C%E6%89%8B%E8%BD%A6/id1123437789";
    }
  }
};
</script>
<style lang="scss" scoped>
.downloadPage {
  height: 100%;
  background: url(../../assets/images/downBg.png) no-repeat bottom;
  background-size: contain;
  .logo {
    width: 1.01rem;
    margin: 0.24rem 0.2rem;
  }
  .main {
    padding-top: 0.18rem;
    text-align: center;
    .icon {
      width: 0.7rem;
      height: 0.7rem;
    }
    .app {
      font-size: 0.2rem;
      font-weight: bold;
      line-height: 0.28rem;
      margin: 0.24rem 0 0.08rem;
    }
    .title {
      font-size: 0.16rem;
      line-height: 0.22rem;
      color: #3a3d46;
    }
    .c_redBtn {
      width: 1.88rem;
      margin: 0.3rem auto 0.7rem;
    }
    .introduce {
      font-size: 0.15rem;
      color: #8d8d91;
      line-height: 0.2rem;
    }
  }
}
</style>
